<template>
  <div>
    <v-app-bar
      :elevation="outlined ? 0 : elevation"
      :height="isPublicEnvironment ? 64 : 56"
      flat
      color="var(--z-theme-background)"
      class="the-topbar"
      :class="{ 'the-topbar--outlined': outlined && !transparent, 'the-topbar--transparent': transparent }"
    >
      <div class="the-topbar__start">
        <TheTopbarLogo
          v-if="withLogo"
          :to="computedHomeRoute"
        />

        <TheTopbarContextSwitch v-if="showContextSwitch" />

        <slot
          v-if="shouldShowContent"
          name="start-content"
        />
      </div>

      <div
        v-if="shouldShowContent && $slots['center-content']"
        class="the-topbar__center"
      >
        <slot name="center-content" />
      </div>

      <div
        v-if="!hideEnd"
        class="the-topbar__end"
      >
        <TheTopBarAlertCenter v-if="alerts.length > 0" />
        <slot
          v-if="shouldShowContent"
          name="end-content"
        />
        <TheTopbarProfileMenu v-if="showProfileMenu" />
        <deck-button
          v-if="shouldShowOnMobile"
          is-ready
          kind="ghost"
          color="controls"
          icon="bars"
          text="menu"
          @click="showMenu = true"
        />
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isPublicEnvironment && $vuetify.display.mdAndDown"
      v-model="showMenu"
      location="right"
      class="the-topbar__mobile-menu"
    >
      <slot name="menu-content" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from '~/assets/javascript/modules/vuex';

export default defineComponent({
  name: 'TheTopbar',
  components: {
    TheTopbarProfileMenu: defineAsyncComponent(() => import('./_profile-menu')),
    TheTopbarLogo: defineAsyncComponent(() => import('~/components/topbars/topbar-logo')),
    TheTopBarAlertCenter: defineAsyncComponent(() => import('./_alert-center')),
    TheTopbarContextSwitch: defineAsyncComponent(() => import('./_context-switch')),
  },
  props: {
    elevation: { type: Number, default: 0 },
    homeRoute: { type: Object, default: undefined },
    outlined: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    withLogo: { type: Boolean, default: true },
    hideEnd: { type: Boolean, default: false },
  },
  setup() {
    return {
      ...mapState('studio', ['inStudio']),
      ...mapGetters('environment', ['isPublicEnvironment']),
      ...mapGetters('workspace', ['alerts']),
    };
  },
  data() {
    return {
      showMenu: false,
    };
  },

  computed: {
    computedHomeRoute() {
      if (this.homeRoute) {
        return this.homeRoute;
      }

      return this.localePath({
        name: 't-tenantSlug',
        params: {
          tenantSlug: this.$route.params.tenantSlug,
        },
      });
    },
    isAdmin() {
      return this.$auth?.user?.role === 'admin';
    },
    showContextSwitch() {
      return this.$auth.loggedIn && this.isAdmin && this.inStudio;
    },
    showProfileMenu() {
      return !this.isPublicEnvironment && this.$auth.loggedIn;
    },
    shouldShowOnMobile() {
      return this.isPublicEnvironment && this.$vuetify.display.smAndDown;
    },
    shouldShowContent() {
      return !this.isPublicEnvironment || (this.isPublicEnvironment && this.$vuetify.display.mdAndUp);
    },
  },
});
</script>

<style lang="scss">
.the-topbar {
  background-color: var(--z-theme-surface) !important; // v-app-bar override
  left: 0 !important; // v-app-bar override
  z-index: 200 !important; // v-app-bar override

  .v-toolbar__content {
    display: grid;
    gap: 24px;
    padding-block: 0; // v-app-bar override
    padding-inline: 12px; // v-app-bar override
    // Center column at the "true center" of the topbar, independent of the content of the other columns
    // |start   |center|   end|
    grid-template-areas: "start center end";
    grid-template-columns: minmax(max-content, 1fr)  minmax(0, auto)  minmax(max-content, 1fr);
    overflow: visible;
  }
}

.the-topbar--outlined.v-toolbar.v-toolbar {
  box-shadow: 0 1px 0 0 var(--z-color-shade-light-6) !important;
}

.the-topbar--transparent {
  background-color: transparent !important;
}

.the-topbar__start,
.the-topbar__center,
.the-topbar__end {
  display: flex;
  padding-block: 8px;
  height: inherit;
  align-items: center;
  gap: 12px;
}

.the-topbar__start {
  grid-area: start;
}

.the-topbar__center {
  grid-area: center;
}

.the-topbar__end {
  justify-self: flex-end;
  grid-area: end;
}

.the-topbar__general-actions {
  display: flex;
  gap: 4px;
}

.the-topbar__mobile-menu {
  height: 100% !important;
  top: 0 !important;
  max-width: calc(100% - 36px) !important; // Keep part of the overlay visible to allow for closing the menu // v-navigation-drawer override
  padding: 16px;

  .v-navigation-drawer__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
